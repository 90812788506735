// const API_URL = 'http://localhost:3005/';
const API_URL = 'https://qwarkocr-api.kuarasoftware.es/';
export const BASE_URL = window?._env?.LOCAL_API ?? API_URL;

export const MODEL = {
  ATTACHMENT: '/attachment',
  INVOICE: '/invoice',
  PAYROLL: '/payroll',
  MOD190: '/mod190',
  NOMINAL_RETENTION: '/nominalRetention',
  PROJECT: '/project',
  COMPANY: '/company',
  COMPANY_DATA: '/company/data',
  PROVIDER: '/provider',
  ENTITY: '/entity',
  USER: '/user',
  USER_INFO: '/user/userInfo',
  PROFILE: '/user/profile',
  ESTABLISHMENT: '/establishment',
  ESTABLISHMENT_INFO: '/establishment/info',
  ESTABLISHMENT_SETTINGS: '/establishment/settings',
  UNPROCESSED_DOCUMENT: '/unprocessedDocument',
};
