import { useCallback, useMemo } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { keyBy } from '../utils';
import { useApp } from '../utils/app';
import { MODEL } from '../constants/api';

const opts = { revalidate: false };

export const useStore = () => {
  const { mutate } = useSWRConfig();

  /**
   * Sets a value into store
   * @param {string} key Store key
   * @param {any} data Object to set
   * @returns boolean
   */
  const set = useCallback(
    async (key, data) => {
      return await mutate(key, async () => data, opts);
    },
    [mutate]
  );

  /**
   * Inserts or updates an object into store
   * @param {string} key Store key
   * @param {any} data Object to insert or update
   * @returns boolean
   */
  const upsert = useCallback(
    async (key, data) => {
      return await mutate(key, async (prev) => {
        const PREV = keyBy(prev ?? []);
        const NEW = keyBy(data ?? []);

        return Object.values({ ...PREV, ...NEW });
      });
    },
    [mutate]
  );

  /**
   * Removes one or many items from store
   * @param {string} key Store key
   * @param {string | string[]} ids IDs to remove
   * @returns boolean
   */
  const remove = useCallback(
    async (key, ids) => {
      return await mutate(key, async (prev) => {
        const result = keyBy(prev ?? []);

        (Array.isArray(ids) ? ids : [ids]).forEach((id) => {
          delete result[id];
        });

        return Object.values(result);
      });
    },
    [mutate]
  );

  return useMemo(() => ({ set, upsert, remove }), [set, upsert, remove]);
};

export const useDataSource = (key) => {
  const { data } = useSWR(key);
  return useMemo(() => Object.values(data ?? {}), [data]);
};

export const useCompanyDataSource = (key) => {
  const { data } = useSWR(key);
  const { selectedCompany } = useApp();

  return useMemo(
    () => Object.values(data ?? {}).filter((x) => !selectedCompany || x.company === selectedCompany),
    [data, selectedCompany]
  );
};

export const useData = (key) => {
  const { data } = useSWR(key);
  return data;
};

export const useCompanies = () => {
  const companyInfos = useDataSource(MODEL.COMPANY_INFO);

  return useDataSource(MODEL.COMPANY)
    .filter((x) => !x.masterCompany)
    .map((company) => {
      const companyInfo = companyInfos.find((x) => x.company === company._id);
      return { ...company, companyInfo };
    });
};
