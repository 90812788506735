import { Form, LoadPanel } from 'devextreme-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { get } from '../actions';
import { login } from '../actions/auth';
import { MODEL } from '../constants/api';
import { useStore } from '../store';
import { useApp } from '../utils/app';

export const AuthContainer = () => {
  const store = useStore();
  const { setDataLoaded } = useApp((s) => s.set);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const doLogin = useCallback(
    async ({ username, password, remember }) => {
      setLoading(() => true);
      const hasAccess = await login({ username, password, remember });
      if (!hasAccess) {
        setLoading(() => false);
        return;
      }

      const establishmentInfo = await get(MODEL.ESTABLISHMENT_INFO);

      const data = await Promise.all(toFetch.map(get));

      await Promise.all([
        store.set(MODEL.ESTABLISHMENT_INFO, establishmentInfo),
        ...toFetch.map((key, index) => store.set(key, data[index])),
      ]);

      setDataLoaded(() => true);
      setLoading(() => false);
    },
    [store, setDataLoaded]
  );

  const handleLogin = async (e) => {
    e.preventDefault(true);
    const { formData } = formRef.current.instance.option();
    await doLogin(formData);
  };

  useEffect(() => {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');

    if (username && password) {
      doLogin({ username: atob(username), password: atob(password) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadPanel visible={loading} message="Cargando datos..." />
      <header className="app-header">
        <h1>Qwark</h1>
      </header>
      <section style={{ display: 'grid', placeContent: 'center', height: '100%' }}>
        <div style={{ textAlign: 'center', border: '1px solid #ccc', borderRadius: 4, padding: '64px 32px' }}>
          <h1 style={{ fontWeight: 100, fontSize: '3em' }}>Iniciar sesión</h1>
          <form onSubmit={handleLogin}>
            <Form
              ref={formRef}
              labelMode="floating"
              id="login-form"
              items={[
                {
                  dataField: 'username',
                  validationRules: [{ type: 'required' }],
                  label: {
                    text: 'Usuario',
                  },
                },
                {
                  dataField: 'password',
                  validationRules: [{ type: 'required' }],
                  label: { text: 'Contraseña' },
                  editorOptions: {
                    mode: 'password',
                  },
                },
                {
                  dataField: 'remember',
                  editorType: 'dxSwitch',
                  label: {
                    text: 'Recuérdame',
                  },
                  editorOptions: {
                    switchedOnText: 'Sí',
                    switchedOffText: 'No',
                  },
                },
                {
                  itemType: 'button',
                  buttonOptions: {
                    text: 'Iniciar sesión',
                    type: 'default',
                    icon: 'key',
                    useSubmitBehavior: true,
                  },
                  horizontalAlignment: 'center',
                },
              ]}
            />
          </form>
        </div>
      </section>
    </>
  );
};

const toFetch = [
  MODEL.ESTABLISHMENT,
  MODEL.ESTABLISHMENT_SETTINGS,
  MODEL.PROFILE,
  MODEL.USER,
  MODEL.USER_INFO,
  MODEL.PROVIDER,
  MODEL.ENTITY,
  MODEL.COMPANY,
  MODEL.PROJECT,
];
