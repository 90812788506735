import { Form, Popup } from 'devextreme-react';
import { useCallback, useRef } from 'react';
import { MODEL } from '../../constants/api';
import { FormDefinitions } from '../../constants/FormDefinitions';
import { useDataSource, useStore } from '../../store';
import { post, put } from '../../actions';

export default function useGeneralForm() {
  const popupRef = useRef();
  const formRef = useRef();
  const storedForms = useRef([]);
  const forms = useForms();
  const store = useStore();

  const openForm = useCallback(
    (formKey, formData) => {
      const popup = popupRef.current.instance;
      const form = formRef.current.instance;

      const { formKey: currentFormKey, formData: currentFormData } = form.option();

      if (currentFormKey) {
        storedForms.current.push({ formKey: currentFormKey, formData: currentFormData });
      }

      const { title, items } = forms[formKey];

      popup.option('title', title);
      popup.option('visible', true);

      form.option('formData', formData);
      form.option('items', items);
      form.option('formKey', formKey);
    },
    [forms]
  );

  const closeCurrentForm = useCallback(() => {
    const popup = popupRef.current.instance;
    const form = formRef.current.instance;

    const lastForm = storedForms.current.pop();

    if (!lastForm) {
      popup.option('title', '');
      popup.option('visible', false);

      form.option('formData', {});
      form.option('items', []);
      form.option('formKey', '');
      return;
    }
    const { formKey, formData } = lastForm;
    const { title, items } = forms[formKey];

    popup.option('title', title);
    popup.option('visible', true);

    form.option('formData', formData);
    form.option('items', items);
    form.option('formKey', formKey);
  }, [forms]);

  const save = async () => {
    const form = formRef.current.instance;
    const { isValid } = form.validate();

    if (!isValid) return false;

    const { formData, formKey } = form.option();

    const result = await (formData._id ? put : post)(formKey, formData);
    if (!result) return false;

    await postSave({ formKey, formData, obj: result });

    await store.upsert(formKey, result);
    return true;
  };

  const saveAndClose = async () => {
    const canClose = await save();
    if (!canClose) return;

    closeCurrentForm();
  };

  const postSave = async ({ formKey, formData, obj }) => {
    if (!formData.updateInvoice) return;

    const field = {
      [MODEL.PROVIDER]: 'provider',
    }[formKey];

    if (!field) return;

    await put(`/invoice/${formData.updateInvoice}`, { [field]: obj._id });
  };

  const popupToolbar = [
    {
      location: 'after',
      toolbar: 'bottom',
      widget: 'dxButton',
      options: {
        text: 'Guardar',
        icon: 'save',
        type: 'default',
        onClick: save,
      },
    },
    {
      location: 'after',
      toolbar: 'bottom',
      widget: 'dxButton',
      options: {
        text: 'Guardar y cerrar',
        icon: 'save',
        type: 'default',
        onClick: saveAndClose,
      },
    },
    {
      location: 'after',
      toolbar: 'bottom',
      widget: 'dxButton',
      options: {
        text: 'Cancelar',
        onClick: closeCurrentForm,
      },
    },
  ];

  const GeneralForm = () => {
    return (
      <Popup ref={popupRef} resizeEnabled={true} shading={false} showCloseButton={false} toolbarItems={popupToolbar}>
        <Form ref={formRef} labelMode="floating" />
      </Popup>
    );
  };

  return { GeneralForm, openForm, closeCurrentForm };
}

const useForms = () => {
  const companies = useDataSource(MODEL.COMPANY);
  return {
    [MODEL.PROVIDER]: {
      title: 'Proveedor',
      items: [
        FormDefinitions.ID,
        { ...FormDefinitions.Company(companies), colSpan: 2 },
        {
          itemType: 'group',
          colSpan: 2,
          colCount: 3,
          items: [
            { ...FormDefinitions.Name, colSpan: 2 },
            FormDefinitions.NIF,
            { ...FormDefinitions.Email, colSpan: 2 },
            FormDefinitions.EnableNotifications,
          ],
        },
      ],
    },
  };
};
