import { createContext, useContext, useEffect, useState } from 'react';
import { useData, useStore } from '../store';
import { BASE_URL, MODEL } from '../constants/api';
import { io } from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const profile = useData(MODEL.PROFILE);
  const api = useStore();

  useEffect(() => {
    if (!profile) return;
    const { id, establishment } = profile;
    const URL = `${BASE_URL}${establishment}`;
    const socket = io(URL);
    setSocket(() => socket);
    console.log(`Listening to: ${URL}`);
    socket.emit('conectado', id);

    return () => {
      if (socket) {
        console.log(`Stop listening to: ${URL}`);
        socket.close();
      }
    };
  }, [profile, api]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
