import { Button } from 'devextreme-react';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div style={{ padding: 16, textAlign: 'center' }}>
          <h2>Ha ocurrido un error.</h2>
          <div style={{ maxWidth: 450, margin: '0 auto' }}>
            <p>
              Por favor, póngase en contacto con{' '}
              <a href="mailto:support@kuarasoftware.com?subject = Error Qwark OCR">support@kuarasoftware.com</a>{' '}
              indicando los pasos que ha seguido para llegar a este punto.
            </p>
            <p>
              Para facilitarnos el trabajo adjunte el error mostrado a continuación, en caso de ser algo muy concreto,
              si es posible adjunte videos o imagenes de los pasos seguidos.
            </p>
          </div>
          <details style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
            {this.state.error?.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
          <div
            style={{
              display: 'flex',
              gap: 8,
              padding: '8px 0',
              justifyContent: 'center',
            }}
          >
            <Button
              text="Copiar error"
              icon="copy"
              onClick={() =>
                navigator.clipboard.writeText(`${this.state.error?.toString()}${this.state.errorInfo.componentStack}`)
              }
            />
            <Button
              text="Reintentar"
              type="default"
              icon="refresh"
              stylingMode="outlined"
              onClick={() => this.setState({ error: null, errorInfo: null })}
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
