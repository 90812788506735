import axios from 'axios';

export const login = async ({ username, password, remember }) => {
  return await axios
    .post('/user/login', { username, password })
    .then(({ data }) => {
      if (!data) return false;

      const { access_token } = data;
      axios.defaults.headers.Authorization = `Bearer ${access_token}`;

      if (remember) {
        const user = btoa(username);
        const pass = btoa(password);

        localStorage.setItem('username', user);
        localStorage.setItem('password', pass);
      }
      return true;
    })
    .catch(() => {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
      return false;
    });
};
