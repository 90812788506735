import { DropDownButton, LoadPanel, SelectBox } from 'devextreme-react';
import { Suspense, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { clickInsideElement, getProfilePicture } from '../../utils';
import { useAppRoutes } from '../../utils/routes';
import { Routes, Route, useNavigate, Navigate } from 'react-router';
import { useData, useDataSource } from '../../store';
import { MODEL } from '../../constants/api';
import { useApp } from '../../utils/app';
import ErrorBoundary from '../ErrorBoundary';
import { SocketProvider } from '../Socket';

export const MainLayout = () => {
  const menuitemsRef = useRef();
  const { selectedCompany, setSelectedCompany, selectedProject, setSelectedProject } = useApp();
  const { currentRoute, currentMaster, getRoute, masterPathname, pathname } = useAppRoutes();
  const navigate = useNavigate();

  const profile = useData(MODEL.PROFILE);
  const companies = useDataSource(MODEL.COMPANY);
  const projects = useDataSource(MODEL.PROJECT);
  const companiesDataSource = companies.map((x) => ({ value: x._id, label: x.name }));

  useEffect(() => {
    const handleHideMenu = (e) => {
      if (menuitemsRef.current.classList.contains('hidden')) return;
      if (clickInsideElement(e, 'app-routes-btn')) return;
      if (!clickInsideElement(e, 'routes-menu')) {
        menuitemsRef.current.classList.add('hidden');
      }
    };

    document.addEventListener('click', handleHideMenu);

    return () => {
      document.removeEventListener('click', handleHideMenu);
    };
  }, []);

  useEffect(() => {
    menuitemsRef.current.classList.add('hidden');
  }, [pathname]);

  const getMenuGroups = () => {
    const groups = Object.entries(AppRoutes).reduce((prev, [key, data]) => {
      if (data.group) {
        const group = prev[data.group];
        if (group) {
          group.push(key);
        } else {
          prev[data.group] = [key];
        }
      }
      return prev;
    }, {});

    return Object.entries(groups);
  };

  const menuRoutesRender = (path) => {
    const data = AppRoutes[path] ?? {};
    return (
      <li className="routes-menu-item" data-selected={path === pathname} key={path}>
        <Link to={path}>
          {data.icon ? <i className={`dx-icon-${data.icon}`} /> : null}
          {data.title}
        </Link>
        {data.subRoutes ? <ul>{data.subRoutes.map(menuRoutesRender)}</ul> : null}
      </li>
    );
  };

  const handleChangeCompany = (e) => {
    const company = e.value;
    const project = projects.find((x) => (x.companies ?? []).includes(selectedCompany));

    setSelectedCompany(company);
    setSelectedProject(project);

    if (company) {
      localStorage.setItem(`${profile.id}_selectedCompany`, company);
      localStorage.setItem(`${profile.id}_selectedProject`, project);
    } else {
      localStorage.removeItem(`${profile.id}_selectedCompany`);
      localStorage.removeItem(`${profile.id}_selectedProject`);
    }
    e.element.querySelector('input.dx-texteditor-input').blur();
  };

  const handleChangeProject = (e) => {
    const project = e.value;

    setSelectedProject(project);

    if (project) {
      localStorage.setItem(`${profile.id}_selectedProject`, project);
    } else {
      localStorage.removeItem(`${profile.id}_selectedProject`);
    }
  };

  return (
    <SocketProvider>
      <header className="app-header">
        <i
          className="app-routes-btn dx-icon-menu"
          style={{ fontSize: 18 }}
          onClick={() => {
            menuitemsRef.current.classList.toggle('hidden');
          }}
        />
        <div className="app-header-content">
          <section className="title">
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <h1 style={{ flexGrow: 1, display: 'flex', alignItems: 'end', paddingBottom: 8 }}>
                <Link
                  style={{ textDecoration: 'none', color: 'white', textTransform: 'uppercase', fontSize: 18 }}
                  to={masterPathname}
                >
                  {currentMaster?.title ?? currentRoute.title}
                </Link>
              </h1>
              <nav className="sub-pages">
                {(currentMaster?.subRoutes ?? currentRoute?.subRoutes ?? []).map((route) => {
                  const { title } = getRoute(route);
                  return (
                    <Link key={route} className="subRoute" data-selected={route === pathname} to={route}>
                      {title}
                    </Link>
                  );
                })}
              </nav>
            </div>
            <div style={{ textAlign: 'right' }}>
              <div style={{ display: 'flex', gap: 8, alignItems: 'center', alignSelf: 'start' }}>
                <div style={{ display: 'grid', gap: 4 }}>
                  <SelectBox
                    searchEnabled={true}
                    value={selectedCompany}
                    width={300}
                    onValueChanged={handleChangeCompany}
                    dataSource={{
                      paginate: true,
                      pageSize: 10,
                      store: companiesDataSource,
                    }}
                    valueExpr={'value'}
                    displayExpr="label"
                    showClearButton={true}
                    onFocusIn={(e) => {
                      e.element.querySelector('input.dx-texteditor-input').select();
                    }}
                  />
                  <SelectBox
                    searchEnabled={true}
                    value={selectedProject}
                    width={300}
                    onValueChanged={handleChangeProject}
                    dataSource={{
                      paginate: true,
                      pageSize: 10,
                      store: projects.filter((x) => (x.companies ?? []).includes(selectedCompany)),
                    }}
                    valueExpr={'_id'}
                    displayExpr="name"
                    showClearButton={true}
                    onFocusIn={(e) => {
                      e.element.querySelector('input.dx-texteditor-input').select();
                    }}
                  />
                </div>
                <DropDownButton
                  showArrowIcon={false}
                  className="profile-btn"
                  icon={getProfilePicture(profile)}
                  stylingMode="text"
                  dropDownOptions={{ width: 120 }}
                  displayExpr={'text'}
                  items={[
                    { text: 'Perfil', icon: 'user', onClick: () => navigate('/profile') },
                    {
                      text: 'Cerrar sesión',
                      icon: 'runner',
                      onClick: () => {
                        localStorage.removeItem('username');
                        localStorage.removeItem('password');
                        window.location.href = '/';
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </section>
        </div>
        <div ref={menuitemsRef} className="routes-menu hidden">
          {getMenuGroups().map(([title, routes]) => {
            return (
              <section key={title}>
                <h1>{title}</h1>
                <ul>{routes.map(menuRoutesRender)}</ul>
              </section>
            );
          })}
        </div>
        <div className="app-version">v{process.env.REACT_APP_VERSION}</div>
      </header>
      <main>
        <ErrorBoundary>
          <Suspense fallback={<LoadPanel visible={true} message="Cargando..." />}>
            <Routes>
              {Object.entries(AppRoutes).map(([path, { component }]) => {
                return <Route key={path} path={path} Component={component} />;
              })}
              <Route index={true} path="/*" element={<Navigate to={'/invoice/buys'} replace={true} />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </main>
    </SocketProvider>
  );
};
