import { createContext, useContext } from 'react';

export const AppContext = createContext({
  dataLoaded: undefined,
  setDataLoaded: undefined,
  selectedCompany: undefined,
  setSelectedCompany: undefined,
  selectedProject: undefined,
  setSelectedProject: undefined,
  closeCurrentForm: undefined,
  openForm: undefined,
  currentPdf: undefined,
  setCurrentPdf: undefined,
});
export const useApp = () => useContext(AppContext);
