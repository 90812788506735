import { lazy } from 'react';

export const AppRoutes = {
  '/profile': {
    title: 'Perfil',
    component: lazy(() => import('../pages/Profile')),
  },

  //#region Facturas
  '/invoice/buys': {
    title: 'Facturas compra',
    component: lazy(() => import('../pages/BuysInvoiceHistoric')),
    icon: 'folder',
    group: 'Facturas',
    showMenu: true,
    subRoutes: ['/invoice/buys/lines'],
  },
  '/invoice/buys/lines': {
    title: 'Lineas',
    component: lazy(() => import('../pages/Breakdowns')),
    icon: 'detailslayout',
  },
  '/invoices/unprocessed': {
    title: 'Facturas no procesadas',
    component: lazy(() => import('../pages/UnprocessedInvoices')),
    showMenu: true,
    group: 'Facturas',
    icon: 'clear',
  },
  //#endregion

  '/payroll': {
    title: 'Nóminas',
    component: lazy(() => import('../pages/Payroll')),
    icon: 'folder',
    group: 'Personal',
    showMenu: true,
  },
  '/mod190': {
    title: 'MOD. 190',
    component: lazy(() => import('../pages/Mod190')),
    icon: 'folder',
    group: 'Personal',
    showMenu: false,
  },
  '/nominalRetention': {
    title: 'Retención nominal',
    component: lazy(() => import('../pages/Rnt')),
    icon: 'folder',
    group: 'Personal',
    showMenu: false,
  },

  //#region Maestros
  '/projects': {
    title: 'Proyectos',
    component: lazy(() => import('../pages/Project')),
    showMenu: true,
    group: 'Maestros',
    icon: 'folder',
  },
  '/vendors': {
    title: 'Proveedores',
    component: lazy(() => import('../pages/Provider')),
    showMenu: true,
    group: 'Maestros',
    icon: 'group',
  },
  '/entities': {
    title: 'Entidades',
    component: lazy(() => import('../pages/Entity')),
    showMenu: true,
    group: 'Maestros',
    icon: 'globe',
  },
  '/companies': {
    title: 'Empresas',
    component: lazy(() => import('../pages/Company')),
    showMenu: true,
    group: 'Maestros',
    icon: 'globe',
  },
  //#endregion

  //#region Otros
  '/pdfSplitter': {
    title: 'Separador',
    component: lazy(() => import('../pages/Tools/PdfSplitter')),
    icon: 'cut',
    group: 'Otros',
    showMenu: true,
  },
  '/settings': {
    title: 'Configuración',
    component: lazy(() => import('../pages/Settings')),
    showMenu: true,
    group: 'Otros',
    subRoutes: ['/settings/user'],
    icon: 'preferences',
  },
  '/settings/user': {
    title: 'Usuarios',
    component: lazy(() => import('../pages/Users')),
    icon: 'group',
  },
  //#endregion
};
