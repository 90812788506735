import { useLocation } from 'react-router';
import { AppRoutes } from '../constants/routes';

export const useAppRoutes = () => {
  const { pathname } = useLocation();

  const [masterPathname, currentMaster] = Object.entries(AppRoutes).find(
    (x) => pathname.startsWith(x[0]) && pathname !== x[0] && x[0] !== '/'
  ) ?? [null, null];

  return {
    currentMaster,
    currentRoute: AppRoutes[pathname] ?? {},
    getRoute(path) {
      return AppRoutes[path] ?? {};
    },
    masterPathname,
    pathname,
  };
};
