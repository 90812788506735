import axios from 'axios';

export const get = async (url) => {
  return await axios
    .get(url)
    .then(({ data }) => data)
    .catch(() => null);
};

export const put = async (url, data) => {
  return await axios
    .put(url, data)
    .then(({ data }) => data)
    .catch(() => null);
};

export const post = async (url, data) => {
  return await axios
    .post(url, data)
    .then(({ data }) => data)
    .catch(() => null);
};

export const deleteMany = async (url, ids) => {
  return await axios
    .delete(url, { data: ids })
    .then(() => true)
    .catch(() => false);
};

export const processManyDocuments = async (documentType, body) => {
  return await axios
    .post(`${documentType}/socket/processMany`, body)
    .then((response) => response.data)
    .catch(() => null);
};

export const processDocument = async (documentType, doc) => {
  documentType = documentType.split(':').pop();
  return await axios
    .post(`${documentType}/processDocument`, doc)
    .then(({ data }) => data)
    .catch(() => null);
};
