const validationRules = [{ type: 'required' }];

export const FormDefinitions = {
  ID: {
    colSpan: 2,
    dataField: '_id',
    label: { text: 'ID' },
    editorOptions: {
      readOnly: true,
    },
  },
  Name: {
    dataField: 'name',
    label: { text: 'Nombre' },
    validationRules,
  },
  NIF: {
    dataField: 'identificationNumber',
    label: { text: 'NIF' },
    validationRules,
  },
  Email: {
    dataField: 'email',
    label: { text: 'Correo electrónico' },
  },
  EnableNotifications: {
    dataField: 'enableNotifications',
    label: { text: 'Activar notificaciones' },
    editorType: 'dxCheckBox',
  },
  Company: (companies = []) => {
    const dataSource = companies.map(({ _id, name }) => ({
      value: _id,
      label: name,
    }));

    return {
      dataField: 'company',
      label: { text: 'Empresa' },
      editorType: 'dxSelectBox',
      editorOptions: {
        dataSource: {
          store: dataSource,
          paginate: true,
          pageSize: 10,
        },
        valueExpr: 'value',
        displayExpr: 'label',
      },
      validationRules,
    };
  },
  Serie: (dataSource = []) => {
    return {
      dataField: 'serie',
      label: { text: 'Serie' },
      editorType: 'dxSelectBox',
      editorOptions: {
        dataSource,
      },
    };
  },
};
