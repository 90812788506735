import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { v4 } from 'uuid';

export function clickInsideElement(e, className) {
  var el = e.srcElement || e.target;

  if (el.classList.contains(className)) {
    return el;
  }

  while ((el = el.parentNode)) {
    if (el?.classList?.contains(className)) {
      return el;
    }
  }

  return false;
}

export const keyBy = (data = [], key = '_id') => {
  const result = {};
  if (Array.isArray(data)) {
    data.forEach((x) => {
      result[x[key]] = x;
    });
  } else {
    result[data[key]] = data;
  }

  return result;
};

export const downloadLink = (url, fileName) => {
  return () => {
    axios({
      baseURL: null,
      url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
  };
};

export const notification = (message, type = 'info', duration = 3) => {
  notify(
    {
      message,
      width: 'auto',
      animation: {
        show: { type: 'fade', duration: 400, from: 0, to: 1 },
        hide: { type: 'fade', duration: 400, from: 1, to: 0 },
      },
      closeOnClick: true,
      position: { at: 'center top', offset: { x: 0, y: 100 } },
      type,
      displayTime: duration * 1000,
    },
    {
      position: 'top center',
      direction: 'down-stack',
    }
  );
};

export const sumProperties = (dataSource = [], prop) => {
  return dataSource.reduce((prev, next) => {
    const value = next[prop] ?? 0;
    const newValue = isNaN(value) ? 0 : Math.round(value * 100) / 100;

    return (prev += newValue);
  }, 0);
};

export const useDocumentForm = ({ generalItems = [], detailsItems = [], keyValuesItems = [], breakdownField }) => {
  return [
    { dataField: 'document' },
    {
      itemType: 'tabbed',
      deferRendering: false,
      tabs: [
        {
          title: 'Desglose',
          items: [{ dataField: breakdownField }],
        },
        {
          title: 'Cabecera',
          name: 'header',
          items: [
            {
              itemType: 'tabbed',
              tabs: [
                {
                  title: 'General',
                  deferRendering: false,
                  name: 'general',
                  colCount: 2,
                  items: generalItems,
                },
                {
                  title: 'Detalles',
                  colCount: 2,
                  items: [
                    ...detailsItems,
                    { dataField: 'fileName', colSpan: 2 },
                    { dataField: 'observations', colSpan: 2 },
                  ],
                },
                {
                  title: 'Datos proveedor',
                  items: keyValuesItems,
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};

export const readFileAsDataURL = async (file) => {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return result_base64;
};

export const formatDocuments = async (acceptedFiles) => {
  return await Promise.all(
    acceptedFiles.map(async (acceptedFile) => {
      const dataURL = await readFileAsDataURL(acceptedFile);

      return {
        document: dataURL.split('base64,')[1],
        fileName: acceptedFile.name ?? `${v4()}.pdf`,
      };
    })
  );
};

export const getPopupToolbarItem = (instance, name) => {
  return instance.option('editing.popup.toolbarItems').find((x) => x.name === name);
};

export const getProfilePicture = (profile) => {
  if (!profile?.picture?.url) {
    return '/assets/user-profile.png';
  }

  return `${profile.picture.url}?t=${new Date().getTime()}`;
};
