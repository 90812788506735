import { DataGrid, LoadPanel, Popup } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { useRef } from 'react';
import { deleteMany, get, post, put } from '../../actions';
import { MODEL } from '../../constants/api';
import { useData, useStore } from '../../store';
import { notification } from '../../utils';
import { useLocation } from 'react-router';
import { navigateToDocument } from '../../utils/datagrids';
import { createContext } from 'react';
import { useContext } from 'react';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

export const DataGridContext = createContext();
export const useDataGrid = () => useContext(DataGridContext);

let hiddenColumns = [];

export const LocalDataGrid = ({
  documentType,
  columns,
  dataSource,
  formItems,
  toolbarItems,
  onFocusedRowIndexChange,
  onToolbarItemPreparing,
  onEditorPreparing,
  onExporting,
  onRowUpdating,
  onRowInserting,
  onRowRemoving,
  onInitNewRow,
  onRowClick,
  onPostUpdate,
  onPostInsert,
  onPostRefresh,
  onEditingStart,
  allowAdding = true,
  allowUpdating = true,
  allowDeleting = true,
  exportEnabled = true,
  editingMode = 'popup',
  onContentReady,
}) => {
  const contextRef = useDataGrid();
  const gridRef = useRef();

  const { pathname } = useLocation();
  const store = useStore();

  const ref = contextRef ?? gridRef;
  const loadRef = useRef();
  const deleteConfirmRef = useRef();

  const profile = useData(MODEL.PROFILE);
  const id = `${profile._id}_${pathname}`;

  const stateStoring = {
    enabled: true,
    storageKey: id,
    type: 'localStorage',
  };

  const datagridProps = {
    id,
    ref,
    dataSource,
    ...generalProps,
    stateStoring,
    onRowDblClick,
    onToolbarPreparing,
  };

  function onRowDblClick(e) {
    e.component.editRow(e.rowIndex);
  }

  function onToolbarPreparing(e) {
    const { component } = e;
    e.toolbarOptions.items?.forEach((item) => {
      if (item.name === 'filter') {
        item.options.onClick = () => {
          component.option('filterBuilderPopup.visible', true);
        };
      }

      if (item.name === 'deleteMany') {
        item.options.onClick = () => {
          const { selectedRowKeys: keys } = component.option();

          if (!keys.length) {
            notification('No hay filas seleccionadas', 'warning', 2);
            return;
          }

          deleteConfirmRef.current.instance.option('visible', true);
        };
      }

      if (item.name === 'refresh') {
        item.options.onClick = async () => {
          loadRef.current.instance.option('visible', true);

          const docs = await get(documentType);
          if (docs) {
            await store.set(documentType, docs);
          }
          await onPostRefresh?.();
          loadRef.current.instance.option('visible', false);
        };
      }

      onToolbarItemPreparing?.(item, e);
    });
  }

  function handleSave({ closeOnSave }) {
    return () => {
      const { instance } = ref.current;
      const [changes] = instance.option('editing.changes');
      if (!changes) {
        if (closeOnSave) {
          instance.cancelEditData();
        }
        return;
      }

      const { data } = changes;
      if (!Object.keys(data).length) {
        if (closeOnSave) {
          instance.cancelEditData();
        }
        return;
      }

      instance.option('closeOnSave', closeOnSave);
      instance.saveEditData();
    };
  }

  async function updateDocument(e) {
    e.cancel = true;

    const { component: instance } = e;
    const data = getUpdatedData(e);
    loadRef.current.instance.option('visible', true);

    // Custom updating
    if (onRowUpdating) {
      await onRowUpdating({ instance, data });
    } else {
      const result = await put(`${documentType}/${e.key}`, data);
      if (!result) {
        loadRef.current.instance.option('visible', false);
        return;
      }

      await store.upsert(documentType, result);
    }

    if (instance.option('closeOnSave')) {
      instance.option('closeOnSave', false);
      instance.cancelEditData();
    }

    await onPostUpdate?.();

    loadRef.current.instance.option('visible', false);
  }

  async function insertDocument(e) {
    e.cancel = true;
    const { component: instance, data } = e;

    if (onRowInserting) {
      if (!(await onRowInserting({ instance, data }))) {
        return;
      }
    } else {
      const result = await post(documentType, data);
      if (!result) return;

      await store.upsert(documentType, result);
    }
    instance.cancelEditData();
    if (!instance.option('closeOnSave')) {
      instance.addRow();
    }

    onPostInsert?.();
  }

  async function deleteDocument(e) {
    e.cancel = true;
    const { component: instance } = e;
    if (onRowRemoving) {
      if (!(await onRowRemoving({ instance, data: [e.key] }))) {
        return;
      }
      return;
    }
    const result = await deleteMany(documentType, [e.key]);

    if (result) {
      await store.remove(documentType, [e.key]);
    }
  }

  async function setPopupBtnsVisible(visible) {
    const { instance } = ref.current;
    const { isNewRow } = instance.option();

    // Previous doc
    instance.option('editing.popup.toolbarItems[0].visible', visible);

    // Next doc
    instance.option('editing.popup.toolbarItems[1].visible', visible);

    const showSave = (isNewRow && allowAdding) || (!isNewRow && allowUpdating);

    // Save doc
    instance.option('editing.popup.toolbarItems[2].visible', showSave);

    // Save and close
    instance.option('editing.popup.toolbarItems[3].visible', showSave);
  }

  const handleExporting = (e) => {
    e.cancel = true;

    if (onExporting) {
      onExporting?.(e);
      return;
    }

    hiddenColumns = [];
    e.component.beginUpdate();

    // SHOW COLUMNS
    for (let i = 0; i < e.component.columnCount(); i++) {
      const visible = e.component.columnOption(i, 'visible');

      if (!visible) {
        hiddenColumns.push(i);
        e.component.columnOption(i, 'visible', true);
      }
    }

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Lineas');

    exportDataGrid({
      component: e.component,
      worksheet,
    })
      .then(() => {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `Lineas ${dayjs().format('dddd, MMMM D, YYYY H:mm')}.xlsx`
          );
        });
      })
      .then(() => {
        // HIDE COLUMNS
        for (const i of hiddenColumns) {
          e.component.columnOption(i, 'visible', false);
        }

        e.component.endUpdate();
        hiddenColumns = [];
      });
  };

  return (
    <>
      <LoadPanel ref={loadRef} />
      <DataGrid
        {...datagridProps}
        onRowUpdating={updateDocument}
        onRowInserting={insertDocument}
        onRowRemoving={deleteDocument}
        onEditorPreparing={onEditorPreparing}
        onFocusedRowIndexChange={(index) => onFocusedRowIndexChange?.(index)}
        onRowClick={onRowClick}
        showColumnHeaders={true}
        onInitNewRow={(e) => {
          e.component.option('isNewRow', true);
          setPopupBtnsVisible(false);
          onInitNewRow?.(e);
        }}
        onEditingStart={(e) => {
          e.component.option('isNewRow', false);
          setPopupBtnsVisible(true);
          onEditingStart?.(e);
        }}
        onExporting={handleExporting}
        export={{ enabled: true, allowExportSelectedData: true }}
        toolbar={{
          items: [
            { name: 'addRowButton', location: 'before' },
            {
              name: 'refresh',
              location: 'before',
              widget: 'dxButton',
              options: {
                icon: 'refresh',
                hint: 'Actualizar lista',
              },
            },
            {
              name: 'deleteMany',
              location: 'before',
              widget: 'dxButton',
              options: {
                icon: 'trash',
                hint: 'Eliminar filas seleccionadas',
              },
              visible: allowDeleting,
            },
            ...(toolbarItems ?? []),
            {
              name: 'filter',
              location: 'after',
              widget: 'dxButton',
              options: {
                icon: 'filter',
                text: 'Filtros',
              },
            },
            'columnChooserButton',
            ...(exportEnabled ? ['exportButton'] : []),
            'searchPanel',
            {
              name: 'applyFilterButton',
              location: 'center',
              visible: true,
            },
          ],
        }}
        editing={{
          allowAdding,
          allowDeleting,
          allowUpdating,
          mode: editingMode,
          useIcons: true,
          form: {
            labelMode: 'floating',
            items: formItems,
          },
          popup: {
            toolbarItems: [
              {
                name: 'prevDoc',
                location: 'before',
                widget: 'dxButton',
                visible: true,
                options: {
                  icon: 'chevronleft',
                  onClick: () => navigateToDocument({ instance: ref.current.instance, previous: true }),
                },
              },
              {
                name: 'nextDoc',
                location: 'after',
                widget: 'dxButton',
                visible: true,
                options: {
                  icon: 'chevronright',
                  onClick: () => navigateToDocument({ instance: ref.current.instance, previous: false }),
                },
              },
              {
                name: 'saveDoc',
                location: 'after',
                toolbar: 'bottom',
                widget: 'dxButton',
                options: {
                  text: 'Guardar',
                  icon: 'save',
                  type: 'default',
                  onClick: handleSave({ closeOnSave: false }),
                },
              },
              {
                name: 'saveDocAndClose',
                location: 'after',
                toolbar: 'bottom',
                widget: 'dxButton',
                options: {
                  text: 'Guardar y cerrar',
                  icon: 'save',
                  type: 'default',
                  onClick: handleSave({ closeOnSave: true }),
                },
              },
              {
                name: 'cancel',
                location: 'after',
                toolbar: 'bottom',
                widget: 'dxButton',
                options: {
                  text: 'Cancelar',
                  onClick: () => ref.current.instance.cancelEditData(),
                },
              },
            ],
          },
        }}
        onContentReady={onContentReady}
      >
        {(columns ?? []).map((col, index) => (
          <Column key={index} name={col.dataField} {...col} />
        ))}
      </DataGrid>
      <Popup
        ref={deleteConfirmRef}
        title="Eliminar"
        width={'auto'}
        height="auto"
        toolbarItems={[
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            options: {
              text: 'Confirmar',
              icon: 'trash',
              type: 'danger',
              stylingMode: 'outlined',
              onClick: async () => {
                loadRef.current.instance.option('visible', true);
                const { instance } = ref.current;
                const { selectedRowKeys } = instance.option();

                if (onRowRemoving) {
                  if (!(await onRowRemoving({ instance, data: selectedRowKeys }))) {
                    loadRef.current.instance.option('visible', false);
                    return;
                  }
                  loadRef.current.instance.option('visible', false);
                  deleteConfirmRef.current.instance.option('visible', false);
                  return;
                }

                if (await deleteMany(documentType, selectedRowKeys)) {
                  await store.remove(documentType, selectedRowKeys);
                  instance.option('selectedRowKeys', []);
                }
                deleteConfirmRef.current.instance.option('visible', false);
                loadRef.current.instance.option('visible', false);
              },
            },
          },
        ]}
      >
        ¿Realmente desea eliminar las filas seleccionadas?
      </Popup>
    </>
  );
};

const generalProps = {
  keyExpr: '_id',
  filterSyncEnabled: 'auto',
  showBorders: true,
  showRowLines: true,
  repaintChangesOnly: true,
  rowAlternationEnabled: true,
  pager: {
    visible: true,
    allowedPageSizes: [100],
    showInfo: true,
    displayMode: 'adaptive',
    showNavigationButtons: true,
  },
  export: { enabled: true, allowExportSelectedData: true },
  width: '100%',
  height: '100%',
  loadPanel: { enabled: false },
  searchPanel: { visible: true },
  columnFixing: { enabled: true },
  columnChooser: { enabled: true, mode: 'select' },
  columnAutoWidth: true,
  columnResizingMode: 'widget',
  allowColumnResizing: true,
  allowColumnReordering: true,
  paging: { enabled: true, pageSize: 100 },
  selection: {
    allowSelectAll: true,
    mode: 'multiple',
    selectAllMode: 'allPages',
    showCheckBoxesMode: 'always',
  },
  filterRow: { visible: true },
  grouping: { expandMode: 'buttonClick', allowCollapsing: true },
  syncLookupFilterValues: false,
  headerFilter: { visible: true, allowSearch: true },
};

const getUpdatedData = (e) => {
  const { oldData, newData } = e;

  const data = { ...oldData, ...newData };

  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) continue;
    if (typeof value === 'object') {
      data[key] = {
        ...(oldData[key] ?? {}),
        ...(newData[key] ?? {}),
      };
    }
  }

  return data;
};
