import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthContainer } from './components/AuthContainer';
import { MainLayout } from './components/Layout';
import { AppContext } from './utils/app';

import { locale, loadMessages } from 'devextreme/localization';
import devEs from 'devextreme/localization/messages/es.json';
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { useData } from './store';
import { MODEL } from './constants/api';
import axios from 'axios';
import { notification } from './utils';
import useGeneralForm from './components/useGeneralForm';
import { DataGridContext } from './components/LocalDataGrid';
import { useRef } from 'react';

locale('es');
loadMessages(devEs);
dayjs.locale('es');

function App() {
  const profile = useData(MODEL.PROFILE);
  const gridRef = useRef();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentPdf, setCurrentPdf] = useState(null);
  const { GeneralForm, closeCurrentForm, openForm } = useGeneralForm();

  useEffect(() => {
    if (!profile) return;

    setSelectedCompany(() => localStorage.getItem(`${profile.id}_selectedCompany`));
    setSelectedProject(() => localStorage.getItem(`${profile.id}_selectedProject`));
  }, [profile]);

  return (
    <AppContext.Provider
      value={{
        dataLoaded,
        setDataLoaded,
        selectedCompany,
        setSelectedCompany,
        selectedProject,
        setSelectedProject,
        closeCurrentForm,
        openForm,
        gridRef,
        currentPdf,
        setCurrentPdf,
      }}
    >
      <BrowserRouter>
        {dataLoaded ? (
          <DataGridContext.Provider value={gridRef}>
            <MainLayout />
            <GeneralForm />
          </DataGridContext.Provider>
        ) : (
          <AuthContainer />
        )}
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;

axios.interceptors.response.clear();
axios.interceptors.response.use(undefined, handleResponseError);

function handleResponseError(err) {
  const error = err?.response?.data ?? 'Ha ocurrido un error no controlado';
  const message = typeof error === 'object' ? JSON.stringify(error) : error;

  notification(message, 'error');
  return Promise.reject(err);
}
