export const navigateToDocument = async ({ instance, previous = false }) => {
  const options = instance.option();
  const { focusedRowIndex: currentIndex } = options;

  const dataSource = await instance.getDataSource().store().load();

  const maxIndex = dataSource.length - 1;
  const lastPageIndex = instance.pageCount() - 1;
  const pageIndex = instance.pageIndex();

  let newIndex = currentIndex + (previous ? -1 : 1);
  let newPageIndex = pageIndex + (previous ? -1 : 1);

  if (newPageIndex < 0) newPageIndex = lastPageIndex;
  if (newPageIndex > lastPageIndex) newPageIndex = 0;

  if (newIndex < 0) {
    instance.cancelEditData();

    await instance.pageIndex(newPageIndex);
    newIndex = instance.getDataSource().items().length - 1;
  }
  if (newIndex > maxIndex) {
    instance.cancelEditData();

    await instance.pageIndex(newPageIndex);
    newIndex = 0;
  }

  await instance.option('focusedRowIndex', newIndex);
  await instance.option('focusedRowKey', null);
  instance.editRow(newIndex);
};

export const getNextIndex = async ({ instance }) => {
  const key = instance.option('editing.editRowKey');
  const currentIndex = instance.getRowIndexByKey(key);

  const dataSource = await instance.getDataSource().store().load();

  const maxIndex = dataSource.length - 1;
  const lastPageIndex = instance.pageCount() - 1;
  const pageIndex = instance.pageIndex();

  let newIndex = currentIndex + 1;
  let newPageIndex = pageIndex + 1;

  if (newPageIndex < 0) newPageIndex = lastPageIndex;
  if (newPageIndex > lastPageIndex) newPageIndex = 0;

  if (newIndex < 0) {
    instance.cancelEditData();

    await instance.pageIndex(newPageIndex);
    newIndex = instance.getDataSource().items().length - 1;
  }
  if (newIndex > maxIndex) {
    instance.cancelEditData();

    await instance.pageIndex(newPageIndex);
    newIndex = 0;
  }

  return newIndex;
};
